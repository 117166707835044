export const useCategory = (name) =>  {
  const category = {
    '医学类': {
      img: 'yx',
      color: '#1FC28C',
      bg: 'linear-gradient(0deg, #FFFFFF 85%,  #E3FFF6)'
    },
    '建筑类': {
      img: 'jz',
      color: '#0078FF',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, #EDF6FF)'
    },
    '财经类': {
      img: 'cj',
      color: '#E48126',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, #FFF6ED)'
    },
    '法律类': {
      img: 'fl',
      color: '#4D2B1E',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, rgb(77,43,30, 0.2))'
    },
    '学历类': {
      img: 'xl',
      color: '#0078FF',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, rgb(1, 121, 255, 0.2))'
    },
    '招录类': {
      img: 'zl',
      color: '#EA9C12',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, rgb(234,156,18, 0.2))'
    },
    '知识问答': {
      img: 'zswd',
      color: '#1289EA',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, rgb(18,137,234, 0.2))'
    },
    '计算机': {
      img: 'jsj',
      color: '#AFB1DB',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, rgb(175,177,219, 0.2))'
    },
    '外语类': {
      img: 'wy',
      color: '#EBAF6B',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, rgb(235,175,107, 0.2))'
    },
    '外贸类': {
      img: 'wm',
      color: '#1289EA',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, rgb(18,137,234, 0.2))'
    },
    '继续教育': {
      img: 'jxjy',
      color: '#744BF0',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, rgb(116,75,240, 0.2))'
    },
    '资格类': {
      img: 'zg',
      color: '#B58A00',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, rgb(254,235,178, 0.6))'
    },
    '职业技能鉴定': {
      img: 'zyjn',
      color: '#1289EA',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, rgb(18,137,234, 0.2))'
    },
    '知识竞赛': {
      img: 'zsjs',
      color: '#B58A00',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, rgb(254,235,178, 0.6))'
    }
  }
  const nuxtApp = useNuxtApp();
  if (category[name]) {
    return {
      ...category[name],
      bg: nuxtApp?.$darkMode?.isDarkMode?.value ? 'linear-gradient(0deg, #0D0D40 85%,  #232337)' : category[name].bg,
      img: `https://static.ppkao.com/www/images/icon-${category[name].img}.png`
    };
  }
  return {
    img: 'yx',
    color: '#1FC28C',
    bg: 'linear-gradient(0deg, #FFFFFF 85%,  #E3FFF6)'
  }
};

export const useTopicType = (type) => {
  type = Number(type) - 1;
  const nuxtApp = useNuxtApp();
  const types = [
    {
      text: '考试试题',
      color: '#0078FF',
      defaultColor: '#BCC8E1',
      img: 'ksst',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, #D6E9FF)',
      fontColor: 'linear-gradient(180deg, #52A3FF, #F4F9FF)'
    },
    {
      text: '网课题目',
      color: '#BD7AEF',
      defaultColor: '#E1D9FF',
      img: 'wangke',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, #F1E2FF)',
      fontColor: 'linear-gradient(180deg, #A08EEC, #FBF7FF)'
     
    },
    {
      text: '问答栏目',
      color: '#C8812C',
      defaultColor: '#EFDCA3',
      img: 'wenda',
      bg: 'linear-gradient(0deg, #FFFFFF 85%, #FFF1E3)',
      fontColor: 'linear-gradient(180deg, #FF913C, #FFF3E7)'
    }
  ];
  return {
    ...types[type],
    bg: nuxtApp?.$darkMode?.isDarkMode?.value ? 'linear-gradient(0deg, #0D0D40 85%,  #232337)' : types[type].bg,
    img: `https://static.ppkao.com/www/images/icon-${types[type].img}.png`,
    bgImg: `https://static.ppkao.com/www/images/bg-${types[type].img}.png`,
  };
}
