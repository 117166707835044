<template>
  <article :class="{ 'card': true, 'set-attr': showTiku }" :style="{background: theme?.bg }">
    <div class="head" :class="{'hide-attr': showTiku}">
      <div class="tabs">
        <div class="title">
          <img :src="theme?.img"/>
          <h2>{{ category.name }}</h2>
        </div>
        <ul v-if="!showTiku && !isMobile" class="category-tab" ref="categoryTab" @mouseenter="handleClearTimer" @mouseleave="startAutoSlide()">
          <li v-for="(item, index) in levelClassList"
              :key="index"
              ref="liRefs"
              :style="{color: index === activeIndex ? theme?.color : 'inherit'}"
              @mouseover="moveSlider(index, $event)">
                <NuxtLink :to="linkTo(item)" class="t-e">
                  {{ item.name }}
                </NuxtLink>
          </li>
         </ul>
       <NuxtLink :to="linkTo(category)" class="more" :style="{color: theme?.color}" >更多科目></NuxtLink>

      </div>
      <div v-if="!showTiku" class="slide-block" :style="{...sliderStyle, backgroundColor: theme?.color}"></div>
    </div>
    <div class="nav-box" v-if="isMobile" ref="navBox">
      <ul class="nav-group">
        <li v-for="(item, index) in levelClassList" :key="item.id"
            :style="{backgroundColor: index === activeIndex ? theme?.color : 'var(--tabs-item-bg)', color: index === activeIndex ? '#fff' : 'var(--primary-text-color)'}">
          <NuxtLink :to="linkTo(item)">
            <span>{{ item.name }}</span>
          </NuxtLink>
        </li>
      </ul>
    </div>
    <section class="body">
      <ul v-if="showTiku" class="kemu-list">
        <li v-for="item in levelClassList" :key="item.id">
          <NuxtLink :to="linkTo(item)" class="t-e">
            {{ item.name }}
          </NuxtLink>
        </li>
      </ul>
      <ul v-else class="category-wrap" ref="categoryWrap" :style="{transform: `translate3d(${wrapOffset}px, 0, 0)`}">
        <li v-for="(x, index) in levelClassList" :key="index" class="wrap-item">
            <ul class="topic-list">
              <li v-for="y in x.kstklist" :key="y.id">
                <img src="https://static.ppkao.com/www/images/icon-topic.png">
                <NuxtLink :to="linkTo(y)" class="t-e">
                  {{ y.title }}
                </NuxtLink>
              </li>
            </ul>
        </li>
      </ul>
    </section>
  </article>
</template>

<script setup>
const { showTiku, category, isMobile } = defineProps({
  category: {
    type: Object,
    required: true,
    default: {}
  },
  showTiku: { // 控制显示题库卡片的样式
    type: Boolean,
    default: false,
  },
  isMobile: {
    type: Boolean,
    default: false
  }
});
const theme = computed(() => useCategory(category.name));// 配色方案
const levelClassList = computed(() => {
  if (!showTiku && Array.isArray(category.levelclasslist)) {
    return category.levelclasslist.filter(item => item.kstklist && item.kstklist.length > 0);
  }
  return category.levelclasslist;
});

const categoryWrap = ref(null); // 用于wrap计算宽度
const sliderStyle = ref({ width: '0px', left: '0px' }); // 动态设置滑块的样式
const activeIndex = ref(0); // 当前滚动到的tabs
const liRefs = ref([]); // tabs 实例
const categoryTab = ref(null); // 整个tabs的实例计算边界
const navBox = ref(null); // 移动端的选项卡
let lastIndex = 0; // tab 的边界值
let timer = null // 定时器实例
const startAutoSlide = () => {
  timer = setInterval(() => {
    if (activeIndex.value >= lastIndex - 1 && !isMobile)  {
      activeIndex.value = -1; // 回到初始状态因为下面需要+1
    }
    const nextIndex = (activeIndex.value + 1) % levelClassList.value.length
    moveSlider(nextIndex, { target: liRefs.value[nextIndex] })
  }, 5000) // 每3秒切换一次
}
onMounted(() => {
  if (process.client && !showTiku) {
    nextTick(() => {
      if (!isMobile) {
        adjustCategoryTabWidth();
        moveSlider(0, { target: liRefs.value[0] }) // 默认选中第一个 li
      }
      startAutoSlide() // 启动自动切换
    })
  }
})
onUnmounted(() => {
  if (timer) {
    clearInterval(timer) // 组件卸载时清除定时器
  }
})
const wrapOffset = computed(() => {
  return -activeIndex.value * categoryWrap.value?.clientWidth;
});
// 判断显示当前完整展示宽度
const adjustCategoryTabWidth = () => {
  const container = categoryTab.value
  const containerWidth = container.clientWidth
  let totalWidth = 0
  let lastWidth = 0

  // 计算所有 li 元素的总宽度
  for (let index = 0; index < liRefs.value.length; index++) {
    const li = liRefs.value[index];
    const liWidth = li.offsetWidth + 40;
    totalWidth += liWidth;
    // 检查当前总宽度是否超过容器宽度
    if (totalWidth > containerWidth) {
      // 设置最后的索引并结束循环
      lastIndex = index;
      totalWidth -= liWidth;
      break;
    }
  }
  // 如果超出，调整容器的 max-width
  // container.style.maxWidth = `${totalWidth}px`;

}
// 移动滑块
const moveSlider = (index, event) => {
  if (event.target) {
    const liElement = event.target.closest('li');
    const liRect = liElement.getBoundingClientRect()

    // 设置滑块的宽度和位置
    sliderStyle.value = {
      width: `${liRect.width}px`,
      transform: `translate3d(${liElement.offsetLeft}px, 0, 0)`,
      transformOrigin: activeIndex.value > index ? 'left' : 'right' // 设置 transform-origin 为左侧或右侧
    };
  }
  navBox.value?.scrollTo({
    left: index * 108, // 横向滚动距离
    behavior: 'smooth' // 平滑滚动
  });
  activeIndex.value = index
}

const handleClearTimer = () => {
  clearInterval(timer);
}
</script>

<style scoped lang="scss">
.nav-box {
  width: 100%;
  overflow-x: auto;
  .nav-group {
    padding: 0 12px;
    display: flex;
    width: fit-content;
    li {
      margin-right: 8px;
      background: #EEF1F8;
      border-radius: 10px;
      text-align: center;
      a {
        @include text-ellipsis;
        display: block;
        width: 100px;
        padding:8px;
        span {
          font-size: 13px !important;
        }
      }
    }
  }
}
.set-attr {
  height: auto !important;
  margin-bottom: 20px !important;
  .title {
    img {
      width: 26px;
    }
    h2 {
      font-size: 16px !important;
    }
  }
  .more {
    font-size: 14px !important;
  }
}
.card {
  display: block;
  width: 100%;
  border-radius: 8px;
  border: 2px solid var(--border-color);
  height: var(--card-height);
  margin-bottom: 12px;
  .hide-attr {
    padding-bottom: 0 !important;
    border: none !important;
    margin: 14px !important;
  }
  .head {
    margin: var(--card-padding);
    @include flex-bet-center;
    border-bottom: var(--card-head-border);
    padding-bottom: var(--card-head-padding);
    position: relative;
    .more {
      font-size: var(--card-title);
    }
    .slide-block {
      position: absolute;
      bottom: -2px;
      width: 136px;
      height: 2px;
      transition: transform 0.2s ease;
      will-change: transform;
      display: var(--slide-block);
    }
    .tabs {
      flex: 1;
      @include flex-y-center;
      justify-content: space-between;
      .title {
        @include flex-y-center();
        h2 {
          @include font(var(--title-color), 20px, 600);
          margin-left: 10px;
        }
      }
      .category-tab {
        display: flex;
        margin-left: 60px;
        overflow: hidden;
        flex: 1;
        color: var(--title-color);
        li {
          margin-right: 40px;
          flex-grow: 1; /* 让每个 li 根据内容自动调整宽度 */
          max-width: 110px; /* 设置最大宽度，避免单个 li 过宽 */
          a {
            max-width: 100%; /* 确保宽度随内容调整 */
          }
        }
      }
    }
  }

  .body {
    @include flex-y-center;
    margin-top: 12px;
    overflow: hidden;
    .category-wrap {
      white-space: nowrap;
      display: flex; /* 改为使用 flex 布局 */
      transition: transform 0.3s ease; /* 动画过渡效果 */
      will-change: transform; /* 提高性能 */
      width: 100%;
      .wrap-item {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        flex-shrink: 0; /* 防止子元素缩小 */
      }
    }
    .kemu-list {
      padding: 10px 20px;
      li {
        display: inline-block;
        padding-bottom: 16px;
        margin-right: var(--tiku-card-mr);
        max-width: 100px;
        font-size: 14px;
        color: var(--title-color);
        h4 {
          @include text-ellipsis;
        }
      }
    }
    .topic-list {
      @include flex-bet-center;
      flex-wrap: wrap;
      padding: 0 var(--card-padding);
      li {
        border-top: 2px dashed var(--slider-color);
        margin-right: 22px;
        width: var(--card-topic-w);
        display: flex;
        align-items: center;
        &:nth-child(-n+3) {
          border-top: none;
        }
        &:nth-child(-n+9) {
          a {
            color: #FB3F3F;
          }
        }
        a {
          width: 100%;
          padding: var(--card-topic-a-p) 0;
          @include font(var(--title-color), var(--global-font-size));
          display: block;
        }
        img {
          margin-right: 10px;
        }
      }

    }
  }
}

</style>
